.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.brCpName {
  border-bottom: 1px solid #ff0000;
  border-top: 1px solid red;
}

.btnRight {
  right: 0;
}

.imgHome {
  height: 300px;

  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.imgCover {
  object-fit: cover;
  width: 100%;
}

.infoPanel {
  top: 300px;
  background-color: #1a1e21;
  color: white;
  border-radius: 10px;
  box-shadow: -40px 30px 30px -1px rgba(0,0,0,0.7);
  font-size: 130%;
  z-index: 2;
}

.textCenter {
  line-height: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.textLeft {
  color: yellow;
}

.footer{
  background-color: #1a1e21;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
}

.aFooter {
  text-decoration: none;
  color: firebrick;
}

.textDecorationFooter {
  text-decoration: none;
  color: white;
}

a.svg {
  position: relative;
  display: inline-block;
}
a.svg:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left:0;
}